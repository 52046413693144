import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import translationEN from '../../translations/en';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingAvailabilityPanel,
  EditListingDescriptionPanel,
  EditListingFeaturesPanel,
  EditListingInstructionsPanel,
  EditListingInsurancePanel,
  EditListingLocationPanel,
  EditListingNextLevelPanel,
  EditListingOnboardingCallPanel,
  EditListingPayoutsPanel,
  EditListingPhotosPanel,
  EditListingPoliciesPanel,
  EditListingPricingPanel,
  EditListingPromotionsPanel,
  EditListingTrackingDevicePanel,
  EditListingVerificationPanel,
} from '../../components';
import {
  EVENT_LISTACAR_CREATED_DRAFT,
  EVENT_LISTACAR_SELECTED_DELIVERY,
  EVENT_LISTACAR_SELECTED_INSTANTBOOK,
  EVENT_LISTACAR_UPDATED_AVAILABILITY,
  HOST_VERIFY_PUBLISH_BUTTON_ID,
  LISTACAR_ABOUT_BUTTON_ID,
  LISTACAR_AVAILABILITY_BUTTON_ID,
  LISTACAR_CAR_INSTRUCTIONS_BUTTON_ID,
  LISTACAR_CAR_RULES_BUTTON_ID,
  LISTACAR_FEATURES_BUTTON_ID,
  LISTACAR_INSURANCE_BUTTON_ID,
  LISTACAR_LOCATION_BUTTON_ID,
  LISTACAR_NEXT_LEVEL_BUTTON_ID,
  LISTACAR_ONBOARDING_CALL_BUTTON_ID,
  LISTACAR_PAYOUTS_BUTTON_ID,
  LISTACAR_PICTURES_BUTTON_ID,
  LISTACAR_PRICING_BUTTON_ID, LISTACAR_SUBSCRIPTION_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { initiateListACarEventFromTab, pushGTMHostVerify } from '../../util/gtm/gtmHelpers';
import { LISTING_WAITING_TO_BE_VERIFIED, sendEventGeneral } from '../../util/emailNotify';

import css from './EditListingWizard.css';
import {
  createRawPropertiesForGTM,
  getEventsByBucket
} from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';
import EditListingSubscriptionPanel
  from '../EditListingSubscriptionPanel/EditListingSubscriptionPanel';

export const AVAILABILITY = 'availability';
export const DESCRIPTION = 'description';
export const FEATURES = 'features';
export const INSTRUCTIONS = 'instructions';
export const POLICY = 'policy';
export const LOCATION = 'location';
export const PRICING = 'pricing';
export const PROMOTIONS = 'promotions';
export const PHOTOS = 'photos';
export const NEXT_LEVEL = 'nextLevel';
export const ONBOARDING_CALL = 'onboarding-call';
export const TRACKING_DEVICE = 'tracking-device';
// export const SUBSCRIPTION = 'subscription';

export const INSURANCE = 'insurance';
export const VERIFICATION = 'verification';
export const PAYOUTS = 'payouts'
// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  DESCRIPTION,
  FEATURES,
  INSTRUCTIONS,
  POLICY,
  LOCATION,
  PRICING,
  PROMOTIONS,
  AVAILABILITY,
  PHOTOS,
  INSURANCE,
  NEXT_LEVEL,
  VERIFICATION,
  TRACKING_DEVICE,
  PAYOUTS,
  // SUBSCRIPTION
];

const getAnalyticsEventAndButtonFromTab = tab => {
  switch (tab) {
    case LOCATION:
      return [
        event_trigger_ids.LISTACAR_COMPLETED_LOCATION,
        LISTACAR_LOCATION_BUTTON_ID,
        translationEN['EditListingWizard.saveNewLocation'],
      ];
    case DESCRIPTION:
      return [
        event_trigger_ids.LISTACAR_COMPLETED_CARDETAILS,
        LISTACAR_ABOUT_BUTTON_ID,
        translationEN['EditListingWizard.saveNewDescription'],
      ];
    case FEATURES:
      return [
        event_trigger_ids.LISTACAR_COMPLETED_CARFEATURES,
        LISTACAR_FEATURES_BUTTON_ID,
        translationEN['EditListingWizard.saveNewFeatures'],
      ];
    case POLICY:
      return [
        event_trigger_ids.LISTACAR_COMPLETED_CARRULES,
        LISTACAR_CAR_RULES_BUTTON_ID,
        translationEN['EditListingWizard.saveNewPolicies'],
      ];
    case INSTRUCTIONS:
      return [
        event_trigger_ids.LISTACAR_COMPLETED_CARFEATURES,
        LISTACAR_CAR_INSTRUCTIONS_BUTTON_ID,
        translationEN['EditListingWizard.saveNewInstructions'],
      ];
    case PRICING:
      return [
        event_trigger_ids.LISTACAR_COMPLETD_PRICING,
        LISTACAR_PRICING_BUTTON_ID,
        translationEN['EditListingWizard.saveNewPricing'],
      ];
    case PHOTOS:
      return [
        event_trigger_ids.LISTACAR_COMPLETED_PICTURES,
        LISTACAR_PICTURES_BUTTON_ID,
        translationEN['EditListingWizard.saveNewPhotos'],
      ];
    case INSURANCE:
      return [
        event_trigger_ids.LISTACAR_COMPLETED_INSURANCE,
        LISTACAR_INSURANCE_BUTTON_ID,
        translationEN['EditListingWizard.saveNewInsurance'],
      ];
    case AVAILABILITY:
      return [
        null,
        LISTACAR_AVAILABILITY_BUTTON_ID,
        translationEN['EditListingWizard.saveNewAvailability'],
      ];
    // case ONBOARDING_CALL:
    //   return [
    //     null,
    //     LISTACAR_ONBOARDING_CALL_BUTTON_ID,
    //     translationEN['EditListingWizard.saveNewOnboardingCall'],
    //   ];
    case NEXT_LEVEL:
      return [
        event_trigger_ids.LISTACAR_COMPLETED_NEXTLEVELSHARING,
        LISTACAR_NEXT_LEVEL_BUTTON_ID,
        translationEN['EditListingWizard.saveNewNextLevel'],
      ];
    default:
      return [];
  }
};


let haveSentPublish = false;

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.push(to);
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    availability,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    updatedTab,
    updateInProgress,
    intl,
    initialImageUrls,
    showListingInProgress,
    onManageDisableScrolling,
    onFetchTimeSlots,
    timeSlots,
    timeZone,
    stripeProps,
    showPayoutDetails,
    currentUser
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const pushHostVerifyEventGTM = (buttonId, event) => {
    pushGTMHostVerify({
      props,
      event,
      buttonId,
    });
  };

  const [bucketResponse, setBucketResponse] = useState({})

  useEffect(() => {
    const fetchAndTriggerEvents = async () => {
      try {
        const response = await getEventsByBucket('listacar');
        setBucketResponse(response)
        // console.log('Events fetched successfully:', response.data);
      } catch (error) {
        console.error('Error fetching events or triggering GTM event:', error);
      }
    };
    fetchAndTriggerEvents();
  }, []);

  const onCompleteEditListingWizardTab = (tab, updateValues) => {
    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      if (tab === NEXT_LEVEL) {
        try {
            triggerAnalyticsEvent({
              event_id: event_trigger_ids.LISTACAR_SENT_FOR_APPROVAL,
              eventData: bucketResponse.data,
              props: {
                ui: {
                  button: 'Next',
                  page: 'ListingProcess'
                },
                listing: props.listing
              },
              userId: currentUser ? currentUser.id.uuid : null,
            });
          sendEventGeneral({ listingUUID: currentListing.id.uuid, eventType: LISTING_WAITING_TO_BE_VERIFIED });
          // console.log('Event Send!');
        } catch (_error_) {
          console.error('Error while sending event:', _error_);
        }
      }

      onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          // console.log("Listing draft response CCCCC", r);
          if (isNewURI) {
            // initiateListACarEventFromTab({
            //   props,
            //   eventButtonParam: [EVENT_LISTACAR_CREATED_DRAFT, LISTACAR_LOCATION_BUTTON_ID],
            // });
            // initiateListACarEventFromTab({
            //   tab,
            //   props,
            // });

            if(r && r.data && r.data.data) {
              // console.log("Listing drafte send data", r.data.data)
              triggerAnalyticsEvent({
                event_id: event_trigger_ids.LISTACAR_CREATED_DRAFT,
                eventData: bucketResponse.data,
                props: {
                  ui: {
                    button: 'Next',
                    page: 'ListingProcess'
                  },
                  listing: r.data.data
                },
                userId: currentUser ? currentUser.id.uuid : null,
              });
            }
            //Listing draft

          } else {
              try {
                const [event, buttonId, buttonText] = tab ? getAnalyticsEventAndButtonFromTab(tab): [];
                // console.log("Event fired for next listing page", event, buttonId, buttonText, props);
                if(event) {
                  triggerAnalyticsEvent({
                    event_id: event,
                    eventData: bucketResponse.data,
                    props: {
                      ui: {
                        button: buttonText,
                        page: tab
                      },
                      listing: props.listing
                    },
                    userId: currentUser ? currentUser.id.uuid : null,
                  });

            }
              } catch (error) {
                console.log('Failed to initialize event listeners', error);
              }

            // initiateListACarEventFromTab({
            //   tab,
            //   props,
            // });
          }


          if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
          } else {
            handlePublishListing(currentListing.id);
          }
        })
        .catch(e => {

          // No need for extra actions
        });
    } else {
      onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id });
    }
  };

  const onChangeInstantBooking = value => {
    if (value) {
      // initiateListACarEventFromTab({
      //   props,
      //   eventButtonParam: [EVENT_LISTACAR_SELECTED_INSTANTBOOK],
      // });
    }
  };
  const onChangeDeliveryBooking = value => {
    if (value) {
      // initiateListACarEventFromTab({
      //   props,
      //   eventButtonParam: [EVENT_LISTACAR_SELECTED_DELIVERY],
      // });
    }
  };

  const onUpdateAvailability = () => {
    if (!isNewListingFlow) {
      // initiateListACarEventFromTab({
      //   props,
      //   eventButtonParam: [EVENT_LISTACAR_UPDATED_AVAILABILITY],
      // });
    }
  };
  const onUpdateOnboardingCall = () => {
    if (!isNewListingFlow) {
      // initiateListACarEventFromTab({
      //   props,
      //   eventButtonParam: [LISTACAR_ONBOARDING_CALL_BUTTON_ID],
      // });
    }
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      currentUser: props.currentUser,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
      showListingInProgress,
    };
  };

  if (tab !== VERIFICATION) {
    haveSentPublish = false;
  }

  switch (tab) {
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingDescriptionPanel
          {...panelProps(DESCRIPTION)}
          submitButtonId={LISTACAR_ABOUT_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          userDetails = {currentUser}
          timeZone={timeZone}
        />
      );
    }
    case FEATURES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <EditListingFeaturesPanel
          {...panelProps(FEATURES)}
          submitButtonId={LISTACAR_FEATURES_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case POLICY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPolicies'
        : 'EditListingWizard.saveEditPolicies';
      return (
        <EditListingPoliciesPanel
          {...panelProps(POLICY)}
          submitButtonId={LISTACAR_CAR_RULES_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case INSTRUCTIONS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewInstructions'
        : 'EditListingWizard.saveEditInstructions';
      return (
        <EditListingInstructionsPanel
          {...panelProps(INSTRUCTIONS)}
          submitButtonId={LISTACAR_CAR_INSTRUCTIONS_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LOCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingLocationPanel
          {...panelProps(LOCATION)}
          submitButtonId={LISTACAR_LOCATION_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case PRICING: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPricing'
        : 'EditListingWizard.saveEditPricing';
      return (
        <EditListingPricingPanel
          {...panelProps(PRICING)}
          submitButtonId={LISTACAR_PRICING_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case PROMOTIONS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPricing'
        : 'EditListingWizard.saveEditPricing';
      return (
        <EditListingPromotionsPanel
          {...panelProps(PRICING)}
          submitButtonId={LISTACAR_PRICING_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case NEXT_LEVEL: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewNextLevel'
        : 'EditListingWizard.saveEditNextLevel';
      return (
        <EditListingNextLevelPanel
          {...panelProps(NEXT_LEVEL)}
          showPayoutDetails={showPayoutDetails}
          onChangeInstantBooking={onChangeInstantBooking}
          onChangeDeliveryBooking={onChangeDeliveryBooking}
          submitButtonId={LISTACAR_NEXT_LEVEL_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case ONBOARDING_CALL: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewOnboardingCall'
        : 'EditListingWizard.saveNewOnboardingCall';
      return (
        <EditListingOnboardingCallPanel
          {...panelProps(ONBOARDING_CALL)}
          isNewListingFlow={isNewListingFlow}
          submitButtonId={LISTACAR_ONBOARDING_CALL_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }

    case AVAILABILITY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAvailability'
        : 'EditListingWizard.saveEditAvailability';
      return (
        <EditListingAvailabilityPanel
          onFetchTimeSlots={onFetchTimeSlots}
          timeSlots={timeSlots}
          {...panelProps(AVAILABILITY)}
          submitButtonId={LISTACAR_AVAILABILITY_BUTTON_ID}
          onUpdateAvailability={onUpdateAvailability}
          availability={availability}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          isNewListingFlow={isNewListingFlow}
          onManageDisableScrolling={onManageDisableScrolling}
          timeZone={timeZone}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }

    // case SUBSCRIPTION:
    //   const submitButtonTranslationKey = isNewListingFlow
    //     ? 'EditListingWizard.saveNewSubscription'
    //     : 'EditListingWizard.saveEditSubscription';
    //   return (
    //     <EditListingSubscriptionPanel
    //       {...panelProps(SUBSCRIPTION)}
    //       submitButtonId={LISTACAR_SUBSCRIPTION_BUTTON_ID}
    //       submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
    //       onSubmit={values => {
    //         onCompleteEditListingWizardTab(tab, values);
    //       }}
    //       currentUser={props.currentUser}
    //     ></EditListingSubscriptionPanel>
    //   );

    case TRACKING_DEVICE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewTrackingDevice'
        : 'EditListingWizard.saveEditTrackingDevice';
      return (
        <EditListingTrackingDevicePanel
          {...panelProps(TRACKING_DEVICE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      );
    }
    case INSURANCE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewInsurance'
        : 'EditListingWizard.saveEditInsurance';
      return (
        <EditListingInsurancePanel
          {...panelProps(INSURANCE)}
          isNewListingFlow={isNewListingFlow}
          submitButtonId={LISTACAR_INSURANCE_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          // redirectionProp={(to)=> {
          //   if (to === 'subscription') {
          //     const redirectionPath = `/l/${params.slug}/${params.id}/${params.type}/${to}`;
          //     history.push(redirectionPath);
          //   } else if (to === '/contact-us') {
          //     history.push(to);
          //   }
          // }}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case VERIFICATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewVerification'
        : 'EditListingWizard.saveEditVerification';
      const isVerified =
        !!panelProps().currentUser &&
        !!panelProps().currentUser.id &&
        panelProps().currentUser.identityStatus &&
        panelProps().currentUser.identityStatus.hostVerified;
      const underVerify =
        !!panelProps().currentUser &&
        !!panelProps().currentUser.id &&
        panelProps().currentUser.identityStatus &&
        panelProps().currentUser.identityStatus.hostUnderVerify;
      if ((isVerified || underVerify) && !haveSentPublish) {
        haveSentPublish = true;
        // setTimeout(() => {
        //   onCompleteEditListingWizardTab(tab, {});
        // }, 2000);
        onCompleteEditListingWizardTab(tab, {});
      }
      return (
        <EditListingVerificationPanel
          {...panelProps(VERIFICATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          newListingPublished={newListingPublished}
          fetchInProgress={fetchInProgress}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          currentUser={props.currentUser}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, {});
          }}
          handlePushEventGTM={pushHostVerifyEventGTM}
          submitButtonId={HOST_VERIFY_PUBLISH_BUTTON_ID}
        />
      );
    }

    case PAYOUTS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPayouts'
        : 'EditListingWizard.saveEditPayouts';
      // const isVerified =
      //   !!panelProps().currentUser &&
      //   !!panelProps().currentUser.id &&
      //   panelProps().currentUser.identityStatus &&
      //   panelProps().currentUser.identityStatus.hostVerified;
      // const underVerify =
      //   !!panelProps().currentUser &&
      //   !!panelProps().currentUser.id &&
      //   panelProps().currentUser.identityStatus &&
      //   panelProps().currentUser.identityStatus.hostUnderVerify;
      // if ((isVerified || underVerify) && !haveSentPublish) {
      //   haveSentPublish = true;
      //   // setTimeout(() => {
      //   //   onCompleteEditListingWizardTab(tab, {});
      //   // }, 2000);
      //   onCompleteEditListingWizardTab(tab, {});
      // }
      return (
        <EditListingPayoutsPanel
        {...panelProps(PAYOUTS)}
        submitButtonId={LISTACAR_PAYOUTS_BUTTON_ID}
        submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
        stripeProps={stripeProps}
        onSubmit={values => {
          onCompleteEditListingWizardTab(tab, {});
        }}
      />
      );
    }

    case PHOTOS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPhotos'
        : 'EditListingWizard.saveEditPhotos';

      return (
        <EditListingPhotosPanel
          {...panelProps(PHOTOS)}
          submitButtonId={LISTACAR_PICTURES_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          newListingPublished={newListingPublished}
          fetchInProgress={fetchInProgress}
          initialImageUrls={initialImageUrls}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
        />
      );
    }

    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
};

const { array, bool, func, object, oneOf, shape, string } = PropTypes;

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,
  availability: object.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
